import React from "react";

import Trans, { t } from "../Translate";

import Layout from "../../layouts/default";
import { Link } from "gatsby-plugin-react-i18next";

const NotFound = ({ location }) => {
  return (
    <Layout metaTitle={t("404_META_TITLE")}>
      <div className="container">
        <div className="row">
          <div className="col col-12 col-lg-6 m-auto">
            <h1 style={{ fontSize: "22px" }}>
              <Trans i18nKey={"PAGE_NOT_FOUND"} />
            </h1>
            <p>
              <Trans i18nKey={"GO_BACK_TO"} />{" "}
              <Link to="/">
                <Trans i18nKey={"HOME_LOWERCASE"} />
              </Link>{" "}
              <Trans i18nKey={"INSTEAD"} />?
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
